<script setup>
import { useRoute } from 'vue-router'
const route = useRoute()

const backgroundColor = computed(() => route.name.startsWith('account') ? 'bg-white-500' : '')
</script>
<template>
  <div :class="[backgroundColor, 'flex flex-col']">
    <div class="flex-1 flex flex-col min-h-dvh lg:h-full w-full">
      <NavigationBar />
      <div class="flex-grow flex flex-col">
        <NuxtPage />
      </div>
    </div>
    <Footer />
  </div>
</template>
