<script setup lang="ts">
import { useRoute, useRouter } from 'vue-router'

const route = useRoute()
const router = useRouter()
const isTermsExpanded = ref(false)
const localePath = useLocalePath()

function navigateToParagraph (paragraph: string) {
  if (route.fullPath !== localePath('/')) {
    router.push({ path: localePath('/'), hash: `#${paragraph}` })
  } else {
    const element = document.getElementById(paragraph)
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' })
    }
  }
}
</script>
<template>
  <footer class="bg-darkBlue">
    <div class="container">
      <div class="flex py-16 border-b border-gray-500/50 w-full">
        <div class="flex flex-col lg:flex-row items-start gap-12 w-full">
          <div class="flex-[2]">
            <NuxtLinkLocale to="/">
              <IconGtLogo class="w-[274px] lg:w-[380px] text-white" />
            </NuxtLinkLocale>
          </div>
          <div class="flex-1 max-w-[350px] font-redhat font-medium text-md">
            <div class="text-white">
              <NuxtLinkLocale to="termsAndRules">
                {{ $t('terms_and_conditions') }}
              </NuxtLinkLocale>
              <div class="mt-3">
                DUK
              </div>
              <button class="mt-2 flex items-center" @click="isTermsExpanded = !isTermsExpanded">
                {{ $t('company_details') }}
                <div v-if="isTermsExpanded">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12 9L17 14L7 14L12 9Z" fill="white" />
                  </svg>
                </div>
                <div v-else>
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12 15L7 10H17L12 15Z" fill="white" />
                  </svg>
                </div>
              </button>
              <transition>
                <div v-if="isTermsExpanded">
                  <div class="mt-3">
                    {{ $t('firm_name') }}: Find My Lawyer, UAB
                  </div>
                  <div class="mt-3">
                    {{ $t('registration_code') }}: 305805163
                  </div>
                  <div class="mt-3">
                    {{ $t('address') }}: Saulėtekio al. 15-1, LT-10224 Vilnius
                  </div>
                </div>
              </transition>
            </div>
          </div>
          <div class="font-redhat font-medium text-md grid gap-2">
            <div class="text-white">
              {{ $t('have_questions') }}?
            </div>
            <button class="mt-3 text-darkBlue contact-us-button" @click="navigateToParagraph('contactUs')">
              {{ $t('contact_us') }}
            </button>
          </div>
        </div>
      </div>
      <div class="font-redhat font-medium text-white copyright-text container flex items-center h-full py-8">
        © 2024 UAB “Find My Lawyer“
      </div>
    </div>
  </footer>
</template>
<style scoped>

.mobile-footer-content {
  padding-bottom: 149px;
}

.links-container {
  width: 350px;
}

.contact-us-button {
  background-color:white;
  height: 48px;
  width: 197px;
  border-radius: 9999px;
}

.horizontal-separator {
  position: absolute;
  bottom: 92px;
  left: 0px;
  right: 0px;
  border-bottom: 1px solid #F1F1F1;
  width: auto;
}

.mobile-horizontal-separator {
  position: absolute;
  bottom: 100px;
  left: 0px;
  right: 0px;
  border-bottom: 1px solid #F1F1F1;
  width: auto;
}


.mobile-copyright-text {
  position: absolute;
  bottom: 32px;
}
</style>
